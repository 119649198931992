import { FC, ReactNode } from 'react'
import { dateToID, timeDifference } from 'utils/functions'
import ChevronRight from 'public/icons/chevron-right.svg'
import { Link } from 'blitz'
import DOMPurify from 'dompurify'

interface NotificationItemProps {
  isNew?: boolean
  icon?: string | ReactNode
  title?: string | null
  message?: string | null
  href?: string | null
  createdAt: Date
  endTime?: Date | null
}

export const Notificationitem: FC<NotificationItemProps> = ({
  isNew = false,
  icon,
  title,
  message,
  href,
  createdAt,
  endTime,
}) => {
  const isIconString = typeof icon === 'string'

  let timeDifferenceStr = timeDifference(createdAt)

  const now = new Date()

  if (endTime && endTime.getTime() > now.getTime()) {
    timeDifferenceStr = 'sekarang'
  }

  const dateStr = dateToID(createdAt)

  const component = (
    <div
      className={`flex w-full rounded-xl p-2 hover:bg-card msm:p-4 smm:p-5 ${
        isNew ? 'bg-card' : 'bg-section'
      }`}
    >
      {!!icon && (
        <div className="mr-2 flex h-16 w-16 justify-center overflow-hidden msm:mr-4 smm:mr-5 xsm:mr-6">
          {isIconString ? (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(icon) }} />
          ) : (
            icon
          )}
        </div>
      )}
      <div className="flex w-full justify-between">
        <div className="font-sans text-sm smm:text-base">
          <h3
            className="break-normal font-bold text-white"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title ?? '') }}
          />
          <span className="break-words font-medium text-white3">{`${timeDifferenceStr} | ${dateStr}`}</span>
          <p
            className="break-normal font-medium text-white2"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message ?? '') }}
          />
        </div>
        {href && (
          <div className="ml-2 flex flex-col justify-center msm:ml-4">
            <ChevronRight />
          </div>
        )}
      </div>
    </div>
  )

  return !!href ? (
    <Link href={href}>
      <a target="_blank" rel="noreferrer">
        {component}
      </a>
    </Link>
  ) : (
    component
  )
}
