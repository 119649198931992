import { Dialog, Transition } from '@headlessui/react'
import { useNotification } from 'app/core/hooks'
import { FC, Fragment, Suspense } from 'react'
import { Image, useQuery } from 'blitz'
import { Notificationitem } from './NotificationItem'
import { Button, Spinner } from 'silicon-design-system'
import { NewNotificationWrapper } from './NewNotificationWrapper'
import PointIcon from 'public/icons/point-icon.svg'
import ChevronCompactDown from 'public/icons/chevron-compact-down.svg'
import { SkeletonWrapper } from 'app/core/components/Skeleton'
import Skeleton from 'react-loading-skeleton'
import { CloseIcon } from 'app/core/components/Icon'
import Bell from 'public/icons/bell.svg'
import hasNewNotification from '../queries/hasNewNotification'
interface NotificationIconProps {
  className?: string
}

export const BellNotification: FC = () => {
  const [hasNew] = useQuery(hasNewNotification, null)

  return (
    <>
      <Bell className="hover:fill-white2" />
      {hasNew && (
        <span className="absolute top-[2px] right-[2px] h-2 w-2 rounded-full bg-[#EF586E]" />
      )}
    </>
  )
}

export const NotificationIcon: FC<NotificationIconProps> = ({ className }) => {
  const { onOpen } = useNotification()

  return (
    <div className={`relative ${className}`} onClick={onOpen}>
      <Suspense fallback={<Spinner />}>
        <BellNotification />
      </Suspense>
    </div>
  )
}

export const PrivateNotifications: FC = () => {
  const {
    open,
    onClose,
    notifications,
    fetchNextPage,
    hasNew,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useNotification()

  const hasNotif = notifications?.length > 0

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-100 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-lg transform overflow-hidden rounded-3xl bg-section py-8 px-4 text-left align-middle shadow-xl transition-all md:py-10 md:px-5">
              <div className="flex w-full justify-between">
                <Dialog.Title as="h3" className="font-rflex text-3xl font-bold text-white">
                  Your Notifications
                </Dialog.Title>
                <CloseIcon onClose={onClose} className="w-6" />
              </div>
              <div className="mt-2">
                <p className="font-sans text-base font-medium text-white2">
                  Seluruh notifikasi dan riwayat kamu!
                </p>
              </div>

              {/* SEPARATOR */}
              <div className="my-5 h-1 w-full rounded-sm bg-black opacity-10" />

              {/* NOTIFICATIONS */}
              <div className="supersmall-scroll grid max-h-[400px] grid-cols-1 gap-y-5 overflow-y-auto">
                {isFetching ? (
                  <SkeletonWrapper>
                    <Skeleton count={5} className="h-24 w-full rounded-xl md:h-32" />
                  </SkeletonWrapper>
                ) : (
                  <>
                    {hasNew && (
                      <NewNotificationWrapper>
                        {notifications
                          .filter((notif) => !notif.read)
                          .map(({ createdAt, icon, ...notif }) => (
                            <Notificationitem
                              key={notif.id}
                              createdAt={new Date(createdAt)}
                              {...notif}
                              isNew={true}
                              icon={notif.isPointNotification ? <PointIcon /> : icon}
                            />
                          ))}
                      </NewNotificationWrapper>
                    )}
                    {notifications
                      .filter((notif) => notif.read)
                      .map(({ createdAt, icon, ...notif }) => (
                        <Notificationitem
                          key={notif.id}
                          createdAt={new Date(createdAt)}
                          {...notif}
                          icon={notif.isPointNotification ? <PointIcon /> : icon}
                        />
                      ))}
                    {!hasNotif && (
                      <div className="flex flex-col items-center justify-center p-4 text-center sm:p-6 md:p-8">
                        <div className="relative h-48 w-48 md:h-60 md:w-60">
                          <Image
                            src="/images/chill.svg"
                            layout="fill"
                            objectFit="contain"
                            alt="empty-notif"
                          />
                        </div>
                        <p className="mt-2 font-rflex text-base font-bold md:mt-4 md:text-2xl">
                          You don{`'`}t have any notifications yet!
                        </p>
                      </div>
                    )}
                  </>
                )}

                {hasNextPage && (
                  <Button
                    text="Lihat 10 Notifikasi Lagi"
                    variant="secondary"
                    bg="#FFFFFF1A"
                    onClick={fetchNextPage}
                    rightIcon={<ChevronCompactDown />}
                    loading={isFetchingNextPage}
                  />
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
