export const monthID = (month: number) => {
  switch (month) {
    case 1:
      return 'Januari'
    case 2:
      return 'Februari'
    case 3:
      return 'Maret'
    case 4:
      return 'April'
    case 5:
      return 'Mei'
    case 6:
      return 'Juni'
    case 7:
      return 'Juli'
    case 8:
      return 'Agustus'
    case 9:
      return 'September'
    case 10:
      return 'Oktober'
    case 11:
      return 'November'
    case 12:
      return 'Desember'
  }
}

export const dateToID = (date: Date, type = 0) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const dayPad = day.toString().padStart(2, '0')
  const monthPad = month.toString().padStart(2, '0')
  const hourPad = hour.toString().padStart(2, '0')
  const minutePad = minute.toString().padStart(2, '0')
  const secondPad = second.toString().padStart(2, '0')

  const monthStrID = monthID(month)

  if (type === 0) return `${hourPad}:${minutePad} ${dayPad} ${monthStrID}`
  if (type === 1) return `${dayPad} ${monthStrID} ${year}`
  if (type === 2) return `${dayPad} ${monthStrID} ${year} ${hourPad}:${minutePad}`
  if (type === 3) return `${dayPad} ${monthStrID} ${year} ${hourPad}:${minutePad}:${secondPad}`
}
