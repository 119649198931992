export const timeDifference = (date: Date) => {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = new Date().getTime() - date.getTime()

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' detik yang lalu'
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' menit yang lalu'
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' jam yang lalu'
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' hari yang lalu'
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' bulan yang lalu'
  } else {
    return Math.round(elapsed / msPerYear) + ' tahun yang lalu'
  }
}

export const timeDifference2 = (future: Date) => {
  const now = new Date()
  const diff = future.getTime() - now.getTime()
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)
  const months = Math.floor(days / 30)

  if (diff < 0) {
    return '0 detik tersisa'
  }

  if (months > 0) {
    return `${months} bulan tersisa`
  } else if (days > 0) {
    return `${days} hari tersisa`
  } else if (hours > 0) {
    return `${hours} jam tersisa`
  } else if (minutes > 0) {
    return `${minutes} menit tersisa`
  } else {
    return `${seconds} detik tersisa`
  }
}
