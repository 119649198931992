import { FC } from 'react'

export const NewNotificationWrapper: FC = ({ children }) => {
  return (
    <div className="rounded-3xl bg-newNotif p-5">
      <div className="mb-6 w-fit rounded-3xl border-2 bg-[#ffffff1a] py-[7px] px-3 font-sans text-base font-medium">
        Baru
      </div>
      <div className="grid grid-cols-1 gap-5">{children}</div>
    </div>
  )
}
