import { Router } from 'blitz'
import { FC, useState } from 'react'

import { useAuth, useNotification, useWindowSize } from 'app/core/hooks'
import { Transition } from '@headlessui/react'
import BoxArrowLeft from 'public/icons/box-arrow-left.svg'
import ChevronDown from 'public/icons/chevronDown.svg'
import Gear from 'public/icons/gear.svg'
import PersonCircle from 'public/icons/person-circle.svg'
import { classNames } from 'utils/functions'
import { NotificationIcon } from 'app/notifications/components'

type NavbarTabProps = {
  className?: string
}

export const NavbarTab: FC<NavbarTabProps> = ({ className }) => {
  const [open, setOpen] = useState(false)
  const { logout, nickname } = useAuth()
  const size = useWindowSize()

  const { onOpen } = useNotification()

  const onSignout = async () => await logout()
  const gotoOnboarding = () => {
    setOpen(false)
    Router.push('/onboarding')
  }

  return (
    <div
      onMouseLeave={() => setOpen(false)}
      onMouseEnter={() => (size?.width ?? 0) >= 640 && setOpen(true)}
      className={`absolute right-0 top-0 z-10 overflow-hidden rounded-lg rounded-b-lg text-base font-semibold
        ${open ? 'bg-[#373951]' : 'bg-transparent transition-colors delay-500'} ${className}`}
    >
      <button
        aria-expanded={open}
        aria-controls={'profile'}
        onClick={() => setOpen((prev) => !prev)}
        className={`flex h-auto w-32 items-center justify-end rounded-lg p-4 py-3 sm:min-w-max sm:justify-between ${
          open ? 'bg-[#3E405B]' : 'bg-transparent'
        }`}
      >
        <div className="flex items-center font-semibold text-white">
          <PersonCircle className="mr-1 sm:mr-3" />
          <span className="hidden max-w-[65px] overflow-hidden text-ellipsis whitespace-nowrap text-left sm:block">
            {nickname}
          </span>
        </div>

        <ChevronDown
          className={classNames(
            'ml-0 transform transition-transform sm:ml-3',
            open ? '-rotate-180' : 'rotate-0',
          )}
        />
      </button>

      <Transition
        show={open}
        enter="transition-all duration-500"
        enterFrom="h-0"
        enterTo="h-36 sm:h-28"
        leave="transition-all duration-500"
        leaveFrom="h-36 sm:h-28"
        leaveTo="h-0"
        className="overflow-hidden bg-[#373951] "
      >
        <>
          <div
            onClick={() => gotoOnboarding()}
            className="flex w-full cursor-pointer items-center p-3 text-white sm:p-4"
          >
            <Gear className="mr-2" />
            <span>Data Diri</span>
          </div>
          <div
            onClick={onOpen}
            className="flex w-full cursor-pointer items-center p-3 text-white sm:hidden sm:p-4"
          >
            <NotificationIcon className="mr-2" />
            <span>Notifikasi</span>
          </div>
          <div
            onClick={onSignout}
            className="flex w-full cursor-pointer items-center p-3 text-[#DC6677] sm:p-4"
          >
            <BoxArrowLeft className="mr-2" />
            <span>Log out</span>
          </div>
        </>
      </Transition>
    </div>
  )
}
